import React, { useRef, useEffect, useState } from 'react'
import {
  GrapheneRoot,
  Text,
  Stack,
  PrimaryButton,
  Icon,
  InlineTextLink,
  IconButton,
  Header,
  Paragraph,
  SecondaryButton,
} from '@elsevier/graphene'
import DOMPurify from 'dompurify'
import { generateInitialPageData, LoadTracker } from '../analytics'
import '../scss/barrow-main.scss'
import '../scss/modal.scss'
import {
  deleteUploadedFile,
  getUploadedFiles,
  uploadFiles,
} from '../service/BarrowService'

enum UploadStatus {
  select = 'select',
  uploading = 'uploading',
  complete = 'complete',
  error = 'error'
}

const UPLOAD_STATUS_SELECT = UploadStatus.select
const UPLOAD_STATUS_UPLOADING = UploadStatus.uploading
const UPLOAD_STATUS_COMPLETE = UploadStatus.complete
const UPLOAD_STATUS_ERROR = UploadStatus.error

function BarrowUI(props: any) {
  const [status, setStatus] = useState(UPLOAD_STATUS_SELECT)
  const [progress, setProgress] = useState(0)
  const [collection, setCollection] = useState([])
  const [fileToDelete, setFileToDelete] = useState(null)
  const [showDeleteFileModal, setShowDeleteFileModal] = useState(false)
  const [showCompleteAlert, setShowCompleteAlert] = useState(false)

  // Adobe Analytics --> Track Initial page load/visit
  useEffect(() => {
    const loadTracker: any = new LoadTracker()
    loadTracker.startTracking()
    loadTracker.stopTracking()
    window.pageData = generateInitialPageData(loadTracker.loadTime)
    window.pageData.page.loadTime = loadTracker.loadTime
    window.pageDataTracker.trackPageLoad()
    fetchUploadedFiles()
    // eslint-disable-next-line
  }, [])

  // Timer for displaying file upload complete alert
  useEffect(() => {
    if (showCompleteAlert) {
      const timeout = setTimeout(() => {
        setShowCompleteAlert(false)
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [showCompleteAlert])

  const fileInputRef: any = useRef()

  function handleSelectFileClick() {
    fileInputRef.current.click()
  }

  async function handleFileChange(event: any) {
    setStatus(UPLOAD_STATUS_UPLOADING)

    const jwt: string = props.match.params.jwt
    const pubContext: string = props.match.params.context

    const { files } = event.target

    const formData = new FormData()

    for (const file of files) {
      formData.append('file', file, file.name)
    }
    try {
      const result = await uploadFiles(
        formData,
        jwt,
        pubContext,
        (prog: number) => {
          setProgress(prog)
        }
      )
      // @ts-ignore
      if (result) {
        // @ts-ignore
        setCollection([result.data, ...collection])
        setStatus(UPLOAD_STATUS_COMPLETE)
        setShowCompleteAlert(true)
      }
    } catch (error) {
      setStatus(UPLOAD_STATUS_ERROR)
    }
  }

  async function fetchUploadedFiles() {
    const jwt: string = props.match.params.jwt
    const pubContext: string = props.match.params.context

    try {
      const results = await getUploadedFiles(jwt, pubContext)
      if (results) {
        // @ts-ignore
        setCollection(...collection, results.data)
      }
    } catch (error) {
      // TODO: put error handling and notification in place for GA
    }
  }

  function renderSelectFiles() {
    return (
      <Stack spacing="24dp">
        <Header headerLevel={1} headerType="page">
          1. Select
        </Header>
        <Text size="font20">
          Choose the files you want and begin your upload.
        </Text>
        <div className="buttons">
          <PrimaryButton onClick={handleSelectFileClick} label="Select Files" />
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleFileChange}
            style={{ opacity: '0' }}
            multiple
          />
        </div>
        {renderPastCompletedUploads()}
      </Stack>
    )
  }

  function renderUploadProgress() {
    const uploadProgress = `${progress}%`
    return (
      <Stack spacing="24dp">
        <Header headerLevel={1} headerType="page">
          2. Upload
        </Header>
        <div className="alert alert-primary" role="alert">
          Your files are uploading; please do not navigate away from this page
          or close your browser until the upload is complete.
        </div>
        <div className="upload-progress">
          {uploadProgress}
          <div
            className="upload-progress-bar"
            style={{ width: uploadProgress }}
          />
          <div className="line" />
        </div>
      </Stack>
    )
  }

  function renderUploadComplete() {
    const site = DOMPurify.sanitize(document.referrer)
    const pubContext = DOMPurify.sanitize(props.match.params.context)

    return (
      <Stack spacing="24dp">
        <Header headerLevel={1} headerType="page">
          3. Download
        </Header>
        <Text size="font20">
          Download your spreadsheet using the links below. Uploads will remain
          available for 30 days. Fill in the spreadsheet with the remaining
          metadata, remove the "filename" column, then upload the spreadsheet
          using the{' '}
          <a
            href={`${site}cgi/editor.cgi?window=batch_upload_excel&context=${pubContext}`}
            target="_blank"
            rel="noreferrer"
          >
            Batch Upload
          </a>{' '}
          tool.
        </Text>
        {renderPastCompletedUploads()}
      </Stack>
    )
  }

  async function deleteFile(id: string) {
    const jwt: string = props.match.params.jwt
    const pubContext: string = props.match.params.context
    try {
      await deleteUploadedFile(id, jwt, pubContext)
      // @ts-ignore
      setCollection(collection.filter((c) => c.id !== id))
      setShowDeleteFileModal(false)
    } catch (error) {
      // TODO: put error handling and notification in place for GA
    }
  }

  function renderPastCompletedUploads() {
    return (
      <div className="collection-table">
        <table className="table">
          <thead>
            <tr>
              <th>Link</th>
              <th>Expires</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {
              // @ts-ignore
              collection.length > 0 &&
                collection.map((item) => {
                  // @ts-ignore
                  const { batchFileUrl, expires } = item.attributes
                  const itemID: string = item['id']
                  const batchFileName = batchFileUrl.match(/^.*\/([^?]+)/)[1]

                  return (
                    <tr key={itemID}>
                      <td>
                        <InlineTextLink href={batchFileUrl}>
                          {batchFileName}
                        </InlineTextLink>
                      </td>
                      <td>{formatExpireDays(expires)} days</td>
                      <td>
                        <IconButton
                          aria={{ 'aria-label': 'Delete batch upload sheet' }}
                          icon="trash"
                          onClick={() => {
                            setShowDeleteFileModal(true)
                            // @ts-ignore
                            setFileToDelete(item.id)
                          }}
                        />
                      </td>
                    </tr>
                  )
                })
            }
          </tbody>
        </table>
      </div>
    )
  }

  function renderDeleteFileModal() {
    return (
      <div className="modal-delete-file">
        <div className="dialog-box">
          <div className="modal-content border-0">
            <div className="modal-header d-flex align-items-center px-4 py-2">
              <div id="modal-info-attention-box">
                <Icon fill="info" name="information-solid-square" size={42} />
                <Header headerLevel={1} headerType="feature">
                  Attention
                </Header>
              </div>
              <IconButton
                aria={{ 'aria-label': 'Close Delete File modal' }}
                icon="delete"
                onClick={() => setShowDeleteFileModal(false)}
              />
            </div>
            <div className="modal-body px-4 ml-4">
              <Paragraph type="font16">
                Are you sure you want to delete this job? Doing so will remove
                the associated spreadsheet and all uploaded files.
              </Paragraph>
            </div>
            <div className="modal-footer border-0">
              <SecondaryButton
                label="Cancel"
                onClick={() => setShowDeleteFileModal(false)}
              />
              <PrimaryButton
                label="Continue"
                navigationType="navigate-right"
                onClick={() => {
                  // @ts-ignore
                  deleteFile(fileToDelete)
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  function formatExpireDays(expires: string) {
    const expiredDate = new Date(expires)
    const today = new Date()
    const diffInTime = expiredDate.getTime() - today.getTime()
    return Math.floor(diffInTime / (1000 * 3600 * 24))
  }

  function renderUploadError() {
    return (
      <div className="col-md-8 mx-auto">
        <Header headerLevel={1} headerType="page">
          3. Download
        </Header>
        <div className="alert alert-danger" role="alert">
          The file transfer was not complete. Partially transferred data will be
          deleted. Please try your upload again.
        </div>
      </div>
    )
  }

  return (
    <GrapheneRoot palette="palette-light">
      <div className="container-fluid" style={containerStyle}>
        {showDeleteFileModal && renderDeleteFileModal()}
        <div
          className="row d-flex align-items-center mt-4"
          style={containerRowStyle}
        >
          <div className="col-md-8 mx-auto">
            <div
              className={`alert alert-success ${
                showCompleteAlert ? 'show' : 'fade'
              }`}
              role="alert"
            >
              Your upload is complete.
            </div>
            {/* {JSON.stringify(collection)} */}
            {status === UPLOAD_STATUS_SELECT && renderSelectFiles()}
            {status === UPLOAD_STATUS_UPLOADING && renderUploadProgress()}
            {status === UPLOAD_STATUS_COMPLETE && renderUploadComplete()}
            {status === UPLOAD_STATUS_ERROR && renderUploadError()}
          </div>
        </div>
      </div>
    </GrapheneRoot>
  )
}

const containerStyle = {
  height: '100vh',
}

const containerRowStyle = {
  height: '75%',
}

export default BarrowUI
