import axios from 'axios'

const UPLOAD_FILE_SERVICE = process.env.REACT_APP_UPLOAD_FILE_SERVICE

export const uploadFiles = async (data, jwt, pubContext, progressCallback) => {
  try {
    const result = await axios({
      url: `${UPLOAD_FILE_SERVICE}/batches/${pubContext}`,
      method: 'post',
      headers: {
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'multipart/form-data',
      },
      data: data,
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent
        const progress = Math.round((loaded / total) * 100)
        progressCallback(progress)
      },
    })
    return result.data
  } catch (error) {
    throw new Error(error)
  }
}

export const getUploadedFiles = async (jwt, pubContext) => {
  try {
    const response = await axios({
      url: `${UPLOAD_FILE_SERVICE}/batches/${pubContext}`,
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'multipart/form-data',
      },
    })

    return response.data
  } catch (error) {
    throw new Error(error)
  }
}

export const deleteUploadedFile = async (batchId, jwt, pubContext) => {
  try {
    const response = await axios({
      url: `${UPLOAD_FILE_SERVICE}/batches/${pubContext}/${batchId}`,
      method: 'delete',
      headers: { Authorization: 'Bearer ' + jwt },
    })
    return response
  } catch (error) {
    throw new Error(error)
  }
}
