//@ts-nocheck

export function generateInitialPageData(loadTime) {
  const claims = getClaims()

  return {
    page: {
      environment: process.env.REACT_APP_ELS_ADOBE_ANALYTICS_ENV,
      name: 'bpdg:editor:upload_files_for_batch:select',
      type: 'CP-CA',
      productName: 'bpdg',
      businessUnit: 'els:rp:st',
      language: 'en',
      loadTime: loadTime.toString(),
      loadTimestamp: '' + new Date().getTime(),
      noTracking: '' + ('trackUser' in claims && !claims.trackUser),
    },
    visitor:
      'accessType' in claims ? claims : { accessType: 'bpdg:ANON_GUEST' },
  }
}

function getClaims() {
  const claims = parseJwt(getToken())

  if (claims) {
    delete claims.iss
    delete claims.sub
  }

  return claims
}

export function parseJwt(c) {
  try {
    const b = c.split('.')[1]
    const a = b.replace('-', '+').replace('_', '/')
    return JSON.parse(window.atob(a))
  } catch (err) {
    return {}
  }
}

function getToken() {
  const container = document.getElementsByClassName('container')[0]
  const url = container ? new URL(container.children[0].src) : window.location

  return url.pathname.split('/')[2]
}

export class LoadTracker {
  constructor() {
    this.loadStart = null
    this.loadTime = null
  }

  startTracking() {
    this.loadStart = Date.now()
  }

  stopTracking() {
    this.loadTime = Date.now() - this.loadStart
  }
}
