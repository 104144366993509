import React from 'react'
import '@elsevier/graphene/dist/style.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import BarrowUI from './component/BarrowUI'

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:context/:jwt" component={BarrowUI} />
      </Switch>
    </Router>
  )
}

export default App
